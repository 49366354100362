<template>
    <client-page class="wrap--login">
        <v-container>

            <div class="login-box">

                <v-img src="/images/ci.svg" contain class="w-120px w-lg-200px mb-50 mb-lg-100" />

                <!-- <h2 class="login-title">
                    <span>{{ $t("common.로그인") }}</span>
                </h2> -->

                <div class="login-box__inputs w-100">
                    <v-text-field v-model="username" outlined hide-details placeholder="아이디" type="text" />
                    <v-text-field v-model="password" @keydown.enter="login" outlined hide-details placeholder="비밀번호" type="password" class="mt-6 mt-lg-12" />
                    <v-btn @click="login" block large color="primary" class="mt-12 mt-lg-24">{{ $t("common.로그인") }}</v-btn>
                    <kakao-login client_id="5713f81f59be4edd95e80de06bbf69f6"></kakao-login>
                    <!-- <v-btn block large color="#ffeb00" class="mt-12 mt-lg-24"><kakao-login client_id="5713f81f59be4edd95e80de06bbf69f6"></kakao-login></v-btn> -->
                </div>

                <div class="mt-6 mt-lg-12">
                    <v-row class="row--x-small" align="center" justify="center">
                        <v-col cols="auto">
                            <router-link to="/login/find-idpw" class="page-text page-text--sm grey-6--text">아이디/비밀번호찾기</router-link>
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center">
                            <v-divider vertical style="height: 12px" class="mx-4" />
                        </v-col>
                        <v-col cols="auto">
                            <router-link to="/join" class="page-text page-text--sm grey-6--text">회원가입</router-link>
                        </v-col>
                    </v-row>
                </div>

                <!-- <div class="login-container">
                    <div class="login-rowgroup">
                        <div class="login-row mt-0">
                            <v-text-field v-model="username" outlined hide-details placeholder="아이디" type="text"></v-text-field>
                        </div>
                        <div class="login-row mt-10 mt-md-16">
                            <v-text-field v-model="password" @keydown.enter="login" outlined hide-details placeholder="비밀번호" type="password"></v-text-field>
                        </div>
                        <div class="login-row login-row--submit mt-16 mt-md-30">
                            <v-btn @click="login" block x-large color="primary">{{ $t("common.로그인") }}</v-btn>
                        </div>
                        <div class="login-row login-row--links">
                            <v-checkbox v-model="remember" :value="true" :label="$t('common.아이디 저장')" hide-details></v-checkbox>

                            <ul class="links">
                                <li><a class="link" href="/join/type">회원가입</a></li>
                                <li>
                                    <a class="link" href="/login/find-id/">{{ $t("common.아이디 찾기") }}</a>
                                </li>
                                <li>
                                    <a class="link" href="/login/find-password/">{{ $t("common.비밀번호 찾기") }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    회원가입/소셜버튼
                    <div class="login-rowgroup login-rowgroup--buttons">
                        <div class="login-row">
                            <v-btn block x-large color="primary" to="/join/type">회원가입</v-btn>
                        </div>
                        <div class="login-row">
                            <kakao-login client_id="53ce3af6a7f73f20faa30fef193adcfb"></kakao-login>
                        </div>
                    </div>
                </div> -->
            </div>

        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import CryptoAES from "@/plugins/crypto-aes";
import KakaoLogin from "@/components/plugins/kakao/kakao-login.vue";

export default {
    components: {
        ClientPage,
        KakaoLogin,
    },
    data() {
        return {
            username: "",
            password: "",

            remember: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function() {
            if (this.$cookies.get("username")) this.username = this.$cookies.get("username");
            // if(this.$cookies.get('password')) this.password = CryptoAES.decrypt(this.$cookies.get('password'));
        },
        async login() {
            try {
                await this.$store.dispatch("login", { username: this.username, password: this.password, remember: this.remember });

                if (this.remember) {
                    this.$cookies.set("username", this.username);
                    this.$cookies.set("password", CryptoAES.encrypt(this.password));
                }

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
    },
};
</script>
