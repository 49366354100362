<template>
    <!-- <a v-bind:id="'kakao-login-btn'" class="button button--social-k" v-bind:href="`https://kauth.kakao.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code`"> -->
        <!-- <slot default>카카오 계정으로 로그인</slot> -->
        <v-btn v-bind:id="'kakao-login-btn'" v-bind:href="`https://kauth.kakao.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code`" block color="#FFEB00" class="mt-12 mt-lg-24" large>
            <i class="icon icon-kakao mr-auto" style="width: 30px; height: 30px;"/><span class="mr-auto"><slot default>카카오톡으로 계속하기</slot></span>
        </v-btn>
        <!-- <div>
            <i class="icon icon-kakao mr-auto"/>
            <span>카카오 계정으로 로그인</span>
        </div>
    </a> -->
</template>

<script>
export default {
    props: {
        client_id: { type: String, default: "" }
    },
    computed: {
        redirect_uri: function(){
            return `${document.location.protocol}//${document.location.hostname}/oauth/kakao`;
        }
    }

}
</script>
