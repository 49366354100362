var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--login"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "login-title"
  }, [_c('span', [_vm._v("아이디 찾기")])]), _c('div', {
    staticClass: "login-container"
  }, [_c('div', {
    staticClass: "login-rowgroup"
  }, [_c('div', {
    staticClass: "login-row login-row--button mt-0"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "이메일",
      "type": "text"
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('v-btn', {
    staticClass: "v-btn--input",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.certify
    }
  }, [_vm._v("인증번호전송")])], 1), _c('div', {
    staticClass: "login-row mt-10 mt-md-16"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "인증번호 입력",
      "type": "number"
    },
    model: {
      value: _vm.otp,
      callback: function ($$v) {
        _vm.otp = $$v;
      },
      expression: "otp"
    }
  })], 1), _c('div', {
    staticClass: "login-row login-row--submit mt-16 mt-md-30"
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.recovery
    }
  }, [_vm._v("확인")])], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }