<template>
    <div>
        <v-text-field v-model="username" outlined hide-details placeholder="아이디" type="text" class="mb-12 mb-lg-24" />
        <v-btn large block color="primary" @click="find">본인인증</v-btn>
    </div>
    <!-- <div class="login-rowgroup">
        <div class="login-row login-row--button">
            <input v-model="username" class="input" type="email" name="" value="" placeholder="이메일">
        </div>
        <div class="login-row login mt-0">
            <v-text-field v-model="username" outlined hide-details placeholder="아이디" type="text"></v-text-field>
        </div>
        <div class="login-row login-row--button mt-10 mt-md-16">
            <div class="select-chip">
                <select v-model="phone1" class="select">
                    <option value="010">010</option>
                    <option value="011">011</option>
                    <option value="016">016</option>
                    <option value="017">017</option>
                    <option value="019">019</option>
                </select>
                <input v-model="phone2" class="input" type="number" placeholder="- 없이 입력">
            </div>
            <v-text-field v-model="email" outlined hide-details placeholder="이메일" type="text"></v-text-field>
            <v-btn @click="certify" color="primary" class="v-btn--input">인증번호전송</v-btn>
        </div>
        <div class="login-row mt-10 mt-md-16">
            <v-text-field v-model="otp" outlined hide-details placeholder="인증번호 입력" type="number"></v-text-field>
        </div>
        <div class="login-row login-row--submit mt-16 mt-md-30">
            <v-btn @click="recovery" block x-large color="primary">확인</v-btn>
        </div>
    </div> -->
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";

export default {
	components: {
		ClientPage,
	},
	props: {
		value: { type: String }
	},
	data() {
		return {
			username: null,
		}
	},
	methods: {

		find() {
			if(this.validate()) {
				this.$emit('find', 'password', this.username)
			}
		},

		validate(){
			try{
				if(!this.username) throw new Error("아이디를 입력해주세요");

				return true;
			}
			catch(error){
				alert(error.message);
			}
			return false;
		},
	},
}
</script>
