<template>
	<client-page class=" wrap--login">
        <div class="container">
            <h2 class="login-title"><span>아이디 찾기</span></h2>

            <div class="login-container">
                <div class="login-rowgroup">
                    <div class="login-row login-row--button mt-0">
                        <!-- <div class="select-chip">
                            <select v-model="phone1" class="select" name="">
                                <option value="010">010</option>
                                <option value="011">011</option>
                                <option value="016">016</option>
                                <option value="017">017</option>
                                <option value="019">019</option>
                            </select>
                            <input v-model="phone2" class="input" type="number" placeholder="- 없이 입력" @input="phone2 = phone2.replace(/\./g,'')">
                        </div> -->
                        <v-text-field v-model="email" outlined hide-details placeholder="이메일" type="text"></v-text-field>
                        <v-btn @click="certify" color="primary" class="v-btn--input">인증번호전송</v-btn>
                    </div>
                    <div class="login-row mt-10 mt-md-16">
                        <v-text-field v-model="otp" outlined hide-details placeholder="인증번호 입력" type="number"></v-text-field>
                    </div>
                    <div class="login-row login-row--submit mt-16 mt-md-30">
                        <v-btn @click="recovery" block x-large color="primary">확인</v-btn>
                    </div>
                </div>
            </div>
        </div>
	</client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";

export default {
	components: {
		ClientPage,
	},
	data() {
		return {
			email: null,

			otp: null
		};
	},
	methods: {
		validate(recovery = false){
			try{
				if(!this.email) throw new Error("이메일을 입력해주세요");
				if(recovery && !this.otp) throw new Error("인증번호를 입력해주세요");

				return true;
			}
			catch(error){
				alert(error.message);
			}
			return false;
		},

		async certify(){
			if(this.validate()){

				await api.v1.users.certify.post({
					type: "email",
					kind: "username",
					email: this.email
				});

				alert("인증번호가 전송되었습니다");
			}
		},

		async recovery(){
			if(this.validate(true)){
				await api.v1.users.recovery.post({
					type: "email",
					kind: "username",
					email: this.email,
					otp: this.otp
				});

				alert("가입한 이메일 정보로 아이디가 발송되었습니다");
			}
		},
	},
}
</script>
