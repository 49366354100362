var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--login"
  }, [_c('v-container', [_c('div', {
    staticClass: "login-box"
  }, [_c('v-img', {
    staticClass: "w-120px w-lg-200px mb-50 mb-lg-100",
    attrs: {
      "src": "/images/ci.svg",
      "contain": ""
    }
  }), _c('div', {
    staticClass: "login-box__inputs w-100"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "아이디",
      "type": "text"
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6 mt-lg-12",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "비밀번호",
      "type": "password"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login.apply(null, arguments);
      }
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('v-btn', {
    staticClass: "mt-12 mt-lg-24",
    attrs: {
      "block": "",
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v(_vm._s(_vm.$t("common.로그인")))]), _c('kakao-login', {
    attrs: {
      "client_id": "5713f81f59be4edd95e80de06bbf69f6"
    }
  })], 1), _c('div', {
    staticClass: "mt-6 mt-lg-12"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "page-text page-text--sm grey-6--text",
    attrs: {
      "to": "/login/find-idpw"
    }
  }, [_vm._v("아이디/비밀번호찾기")])], 1), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "mx-4",
    staticStyle: {
      "height": "12px"
    },
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "page-text page-text--sm grey-6--text",
    attrs: {
      "to": "/join"
    }
  }, [_vm._v("회원가입")])], 1)], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }