var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--login"
  }, [_c('v-container', [_c('div', {
    staticClass: "login-box"
  }, [_c('v-img', {
    staticClass: "w-120px w-lg-200px mb-50 mb-lg-100",
    attrs: {
      "src": "/images/ci.svg",
      "contain": ""
    }
  }), _c('h4', {
    staticClass: "font-size-20 font-size-lg-28 mb-20 mb-lg-40"
  }, [_vm._v(" 아이디/비밀번호 찾기 ")]), _c('v-tabs', {
    staticClass: "v-tabs--primary v-tabs--sm v-tabs--punch mb-20 mb-lg-40",
    attrs: {
      "hide-slider": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("아이디 찾기")]), _c('v-tab', [_vm._v("비밀번호 찾기")])], 1), _c('v-tabs-items', {
    staticClass: "w-100",
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', [_c('find-id', {
    on: {
      "find": _vm.find
    }
  })], 1), _c('v-tab-item', [_c('find-password', {
    on: {
      "find": _vm.find
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  })], 1)], 1)], 1)]), _c('kcp-cert', {
    ref: "kcpCert"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }