<template>
    <div>
        <v-btn @click="$emit('find', 'username')" large block color="primary">본인인증</v-btn>
		<kcp-cert ref="kcp-cert" @input="verify"></kcp-cert>
    </div>
</template>

<script>
import api from "@/api";

export default {
	components: {
	},
	data() {
		return {
			email: null,

			otp: null
		};
	},
	methods: {
		validate(recovery = false){
			try{
				if(!this.email) throw new Error("이메일을 입력해주세요");
				if(recovery && !this.otp) throw new Error("인증번호를 입력해주세요");

				return true;
			}
			catch(error){
				alert(error.message);
			}
			return false;
		},

		async certify(){
			if(this.validate()){

				await api.v1.users.certify.post({
					type: "email",
					kind: "username",
					email: this.email
				});

				alert("인증번호가 전송되었습니다");
			}
		},

		async recovery(){
			if(this.validate(true)){
				await api.v1.users.recovery.post({
					type: "email",
					kind: "username",
					email: this.email,
					otp: this.otp
				});

				alert("가입한 이메일 정보로 아이디가 발송되었습니다");
			}
		},
	},
}
</script>
