<template>
	<client-page class=" wrap--login">
        <v-container>
            <div class="login-box">

                <v-img src="/images/ci.svg" contain class="w-120px w-lg-200px mb-50 mb-lg-100" />

                <h4 class="font-size-20 font-size-lg-28 mb-20 mb-lg-40">
                    아이디/비밀번호 찾기
                </h4>

                <v-tabs v-model="tab" hide-slider class="v-tabs--primary v-tabs--sm v-tabs--punch mb-20 mb-lg-40">
                    <v-tab>아이디 찾기</v-tab>
                    <v-tab>비밀번호 찾기</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" class="w-100">
                    <v-tab-item>
                        <find-id @find="find"/>
                    </v-tab-item>
                    <v-tab-item>
                        <find-password v-model="username" @find="find"/>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </v-container>
        <kcp-cert ref="kcpCert"></kcp-cert>
	</client-page>
</template>

<script>
import api from '@/api'
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import FindId from "@/components/client/login/find-id.vue";
import FindPassword from "@/components/client/login/find-password.vue";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";

export default {
	components: {
		ClientPage,
        FindId,
        FindPassword,
        KcpCert
	},
	data() {
		return {
            tab: null,
		};
	},
    methods: {
        find(type, username){
            console.log(type,username);
            switch(type){
                // 아이디 찾기 클릭했을때
                case "username": {
                    // 본인인증 콜백 등록
                    this.$refs.kcpCert.$off("input");
                    this.$refs.kcpCert.$on("input", async (payload) => {
                        let { username } = await api.v1.users.repair({ type: "username", _cert: payload._certification });
                        alert(`회원님의 아이디는 ${username} 입니다`);
                        // this.username = username;
                    });

                    // 본인인증창 열기
                    this.$refs["kcpCert"].auth();
                    break;
                }
                // 비밀번호 찾기 클릭했을때
                case "password": {
                    // 본인인증 콜백 등록
                    this.$refs.kcpCert.$off("input");
                    this.$refs.kcpCert.$on("input", async (payload) => {
                        let { password } = await api.v1.users.repair({ type: "password", username: username, _cert: payload._certification });
                        alert(`회원님의 임시비밀번호는 ${password} 입니다`);
                    });
                    // 본인인증창 열기
                    this.$refs["kcpCert"].auth();

                    break;
                }
            }
        },
    }
}
</script>
