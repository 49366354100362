var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-btn', {
    attrs: {
      "large": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('find', 'username');
      }
    }
  }, [_vm._v("본인인증")]), _c('kcp-cert', {
    ref: "kcp-cert",
    on: {
      "input": _vm.verify
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }